<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newFileServer"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="fileServers"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_ids="{ item }">
          {{ getTenantTitle(item.tenant_ids) }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editFileServer(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          
        </template>
      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" @submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import FileServerActions from '@/store/content/file_server/actions-types'
import FileServerMutations from '@/store/content/file_server/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "FileServer",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
  },

  data() {
    return {
      loading: true,
      options: {
        sortBy: ['label'],
        sortDesc: [false],
      },
      itemsLength: 0,
      headers: [
        {text: 'Label', value: 'label'},
        {text: 'Url', value: 'url'},
        {text: 'Tenant', value: 'tenant_ids'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "create_file_server",
          eventToEmit: "create-new",
        },
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[FileServerMutations.SET_SELECTED_FILE_SERVER](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[FileServerMutations.SET_FIELD_TENANTS](tenants.items)
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('app', [
      'getIconForContentSettingsItem'
    ]),

    ...mapState({
      tenants: state => state.core.tenant.tenants,
      fields: state => state.content.file_server.fields,
      fileServers: state => state.content.file_server.fileServers,
      selectedFileServer: state => state.content.file_server.selectedFileServer,
      inactiveFilterValue: state => state.content.file_server.inactiveFilterValue,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('content_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "ContentSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('file_servers'),
        },
      ]
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('content/file_server', [
      FileServerActions.SAVE_FILE_SERVER,
      FileServerActions.GET_ALL_FILE_SERVERS,
    ]),

    ...mapMutations('content/file_server', [
      FileServerMutations.SET_SELECTED_FILE_SERVER,
      FileServerMutations.SET_FIELD_TENANTS,
      FileServerMutations.SET_INACTIVE_FILTER_VAL
    ]),

    newFileServer() {
      this.[FileServerMutations.SET_SELECTED_FILE_SERVER](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[FileServerActions.SAVE_FILE_SERVER]()
        .then(() => {
          let message = this.selectedFileServer ? 'file_server_edited' : 'file_server_created'
          
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()

          this.reloadData()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true
      this.[FileServerActions.GET_ALL_FILE_SERVERS](options)
        .then((result) => {
          this.itemsLength = result.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    switchInactive() {
      this.[FileServerMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    editFileServer(fileServer) {
      this.[FileServerMutations.SET_SELECTED_FILE_SERVER](fileServer)
      this.$refs.modal.openModal()
    },

    getTenantTitle(tenantIds) {
      let titles = []

      tenantIds.forEach((id) => {
        const tenant = this.tenants.find(f => f.tenant_id == id)

        if (tenant) {
          titles.push(tenant.title)
        }
      })

      return titles.join(', ')
    },

    getModalTitle() {
      return this.selectedFileServer ? this.$t('edit_file_server') : this.$t('new_file_server')
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },
  }
}

</script>
